import axios from 'axios';
import {AuthModel} from './_models';
import {User} from '../../../../utils/__generated__/graphql';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}/login`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

export function getUserByToken(token: string) {
  return axios.post<User>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  });
}
