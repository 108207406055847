import DatePicker from "react-datepicker";
import React from "react";

interface DatePickerInputProps {
  label: string;
  name: string;
  formik: any;
}
export const DatePickerInput = ({ label, name, formik }: DatePickerInputProps) => (
  <div className='mb-5'>
    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
      <span className='required'>{label}</span>
    </label>
    <DatePicker
      className='form-control form-control-lg form-control-solid'
      name={name}
      selected={formik.values[name] ? new Date(formik.values[name]) : null}
      onChange={val => {
        formik.setFieldValue(name, val);
      }}
    />
  </div>
);
