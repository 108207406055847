import { SportType } from "../../utils/__generated__/graphql";

export * from './AssetHelpers';
export * from './RouterHelpers';
export * from './components/KTSVG';
export * from './components/KTCard';
export * from './components/KTCardBody';
export * from './dataExamples';
export * from './crud-helper/helpers';
export * from './crud-helper/models';
export * from './crud-helper/consts';
export * from './react18MigrationHelpers';

export const sportTypeToIconName: { [key in SportType]: string } = {
  [SportType.Climbing]: 'Climbing',
  [SportType.Coordination]: 'Coordination',
  [SportType.Cycling]: 'Bike',
  [SportType.Ebike]: 'Ebike',
  [SportType.Flexibility]: 'Flexibility',
  [SportType.Going]: 'Walking',
  [SportType.Hiking]: 'Hiking',
  [SportType.Interval]: 'Interval',
  [SportType.Running]: 'Running',
  [SportType.SitToStand]: 'Sit',
  [SportType.SixMwt]: 'SixMinWalkTest',
  [SportType.Squats]: 'Squat',
  [SportType.Strength]: 'Strength',
  [SportType.Swimming]: 'Swimming',
  [SportType.Yoga]: 'Yoga',
  [SportType.LocalMuscleStrengthening]: 'Strength',
  [SportType.Mobilisation]: 'Yoga',
  [SportType.SixMwtInstructed]: 'SixMwtInstructed',
  [SportType.SixMwtAppGuided]: 'SixMwtAppGuided',
  [SportType.Walking]: 'Walking',
};

export function getIntensityColor(intensity: number): string {
      return 'bg-primary';
}
