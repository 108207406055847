import {FormattedMessage} from 'react-intl';

export function StepsSidebar() {
  const steps = [
    {step: 1, label: 'PATIENT.MODAL.DETAILS', desc: 'PATIENT.MODAL.DESCRIPTION'},
    {step: 2, label: 'PATIENT.MODAL.DEVICE', desc: 'PATIENT.MODAL.DEVICE.SELECT'},
    {step: 3, label: 'PATIENT.MODAL.QUESTIONNAIRES', desc: 'PATIENT.MODAL.QUESTIONNAIRES.DESCRIPTION'},
    {step: 4, label: 'PATIENT.MODAL.QRCODE', desc: 'PATIENT.MODAL.QRCODE.DESCRIPTION'}
  ];
  return (
    <div className='stepper-nav ps-lg-10'>
      {steps.map(({ step, label, desc }, index) => (
        <div key={step} className={`stepper-item${index === 0 ? ' current' : ''}`} data-kt-stepper-element="nav">
          {/* begin::Wrapper*/}
          <div className="stepper-wrapper">
            {/* begin::Icon*/}
            <div className="stepper-icon w-40px h-40px">
              <i className="stepper-check fas fa-check"></i>
              <span className="stepper-number">{step}</span>
            </div>
            {/* end::Icon*/}
            {/* begin::Label*/}
            <div className="stepper-label">
              <h3 className="stepper-title"><FormattedMessage id={label} /></h3>
              <div className="stepper-desc"><FormattedMessage id={desc} /></div>
            </div>
            {/* end::Label*/}
          </div>
          {/* end::Wrapper*/}
          {/* begin::Line*/}
          <div className='stepper-line h-40px'></div>
          {/* end::Line*/}
        </div>
      ))}
    </div>
  );
}
