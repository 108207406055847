import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { StepProps } from '../IPatientModels';
import { FormattedMessage } from 'react-intl';
import { KTSVG } from '../../../../helpers';
import { useSelector } from 'react-redux';
import { selectGlobals } from '../../../../../app/store/globals.slice';
import { Dropdown1 } from '../../../content/dropdown/Dropdown1';
import { UpdateQuestionnaireMetadata } from '../../UpdateQuestionnaireMetadata';
import { Questionnaire } from "../../../../../utils/__generated__/graphql";

const Step4 = ({ data, updateData }: StepProps) => {
  const { questionnaires } = useSelector(selectGlobals);
  const isPsychoPatient = useMemo(() => data.illness?.name === 'Psychiatry', [data.illness]);

  const filteredQuestionnaires = useMemo(() => {
    return questionnaires?.filter(
      q => isPsychoPatient ? q.key === 'PSYCHO' : q.key !== 'PSYCHO'
    );
  }, [questionnaires, isPsychoPatient]);

  const [show, setShow] = useState(false);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<Questionnaire | undefined>();

  const updateQuestionnaires = useCallback((name: string, checked: boolean) => {
    const correspondingQuestionnaire = questionnaires?.find(q => q.name === name);
    const questionnaireId = correspondingQuestionnaire ? correspondingQuestionnaire.id : undefined;

    const updatedQuestionnaires = checked
      ? [...data.questionnaires, { name, id: questionnaireId }]
      : data.questionnaires.filter(q => q.name !== name);

    const updatedMetadata = questionnaireId
      ? data.questionnairesMetadata.filter(meta => meta.questionnaire.id !== questionnaireId)
      : [...data.questionnairesMetadata];

    updateData({
      ...data,
      questionnaires: updatedQuestionnaires as Questionnaire[],
      questionnairesMetadata: updatedMetadata
    });
  }, [data, updateData]);

  const handleCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    updateQuestionnaires(name, checked);
  }, [updateQuestionnaires]);

  const showModal = useCallback((questionnaire: Questionnaire) => {
    setSelectedQuestionnaire(questionnaire);
    setShow(true);
  }, []);

  return (
    <>
      {/* begin::Step 4 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        {/* begin::Form Group */}
        <div className='w-100'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
            <FormattedMessage id='PATIENT.MODAL.QUESTIONNAIRES' />
          </label>
          <div className='hover-scroll' style={{ height: '420px' }}>
            {filteredQuestionnaires?.map(({ name, icon, color }, index) => (
              <label key={name} className='d-flex align-items-center justify-content-between mb-6'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-50px me-6'>
                    <span className='symbol-label bg-light-primary'>
                      <KTSVG path={icon} className={`svg-icon-1 ${color}`} />
                    </span>
                  </span>
                  <span className='fw-bolder fs-6'>{name}</span>
                </span>
                <span className='form-check form-check-sm form-check-custom form-check-solid'>
                  <div className='me-3'>
                    <input
                      className='form-check-input '
                      name={name}
                      type='checkbox'
                      checked={data.questionnaires.some(q => q.name === name)}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                  <button
                    type='button'
                    onClick={() => showModal(filteredQuestionnaires[index])}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                  </button>
                  <Dropdown1 />
                </span>
              </label>
            ))}
          </div>
        </div>
        {/* end::Form Group */}
        {selectedQuestionnaire && (
          <UpdateQuestionnaireMetadata
            data={data}
            updateData={updateData}
            questionnaire={selectedQuestionnaire}
            show={show}
            handleClose={() => setShow(false)}
          />
        )}
      </div>
      {/* end::Step 4 */}
    </>
  );
};

export { Step4 };
