import { defaultCreateAppData, ICreateParticipantData } from "./IPatientModels";
import { GlobalState, refreshGlobals, selectGlobals } from "../../../../app/store/globals.slice";
import { useSelector } from "react-redux";
import { useListView } from "../../../../app/modules/apps/patient-management/users-list/core/ListViewProvider";
import { useEffect, useState } from "react";
import { extractData, getFieldsToUpdate } from "./dataTransforms";
import { Patient, useCreatePatientMutation, useUpdatePatientMutation } from "../../../../utils/__generated__/graphql";
import { store } from "../../../../app/store";

const usePatientDataManagementHook = () => {
  const { patients }: GlobalState = useSelector(selectGlobals);
  const { itemIdForUpdate } = useListView();
  const [data, setData] = useState<ICreateParticipantData>(defaultCreateAppData);
  const [dataToSend, setDataToSend] = useState<ICreateParticipantData | undefined>();

  const updateData = (fieldsToUpdate: Partial<ICreateParticipantData>) => {
    setData({ ...data, ...fieldsToUpdate });
  };

  const resetData = () => setData(defaultCreateAppData);

  useEffect(() => {
    itemIdForUpdate === undefined ? resetData() : fetchPatientData(String(itemIdForUpdate ?? 'defaultValue'));
  }, [itemIdForUpdate]);

  const getBaseOptions = () => extractData(data);

  const [createPatient, { data: insPatientData, error: errorInsertPatient }] =
    useCreatePatientMutation();
  const [updatePatient, { data: updPatientData, error: errorUpdPatient }] =
    useUpdatePatientMutation();

  useEffect(() => {
    if (dataToSend && dataToSend.subjectId !== '') {
      const baseOptions = getBaseOptions();
      const action = itemIdForUpdate ? updatePatient : createPatient;
      const variables = itemIdForUpdate
        ? { updatePatientInput: { id: Number(itemIdForUpdate), ...baseOptions } }
        : { createPatientInput: { ...baseOptions } };

      action({ variables: variables as any }).then(() => {});
    }
  }, [dataToSend]);

  useEffect(() => {
    const refreshPatientData = (patient: Patient) => {
      const updatedPatients = patients.filter((p) => p.id !== patient.id);
      updatedPatients.push(patient);
      updatedPatients.sort((a, b) => a.id - b.id);
      store.dispatch(refreshGlobals({ patients: updatedPatients }));
    };

    if (updPatientData) refreshPatientData(updPatientData.updatePatient as unknown as Patient);
    if (insPatientData) store.dispatch(refreshGlobals({ patients: [...patients, insPatientData.createPatient] }));
  }, [updPatientData, insPatientData]);

  const fetchPatientData = (itemId: string) => {
    const patient = patients.find((p) => p.id === Number(itemId));
    if (patient) updateData(getFieldsToUpdate(patient));
  };

  return {
    data,
    errorInsertParticipant: errorInsertPatient,
    errorUpdParticipant: errorUpdPatient,
    insParticipantData: insPatientData,
    patients,
    setDataToSend,
    updParticipantData: updPatientData,
    updateData,
  };
};

export default usePatientDataManagementHook;
