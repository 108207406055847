import {configureStore} from '@reduxjs/toolkit';
import globalsReducer from './store/globals.slice';
import patientReducer from './store/patient.slice';

export const store = configureStore({
  reducer: {
    globals: globalsReducer,
    patient: patientReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
