import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import { CommonType, Illness, Patient, Role, Sensor, User } from "../../utils/__generated__/graphql";

// Define a type for the slice state
export interface GlobalState {
  access_token: string | undefined;
  user: User | undefined;
  devices: any[] | undefined;
  illnesses: Illness[];
  sensors: Sensor[];
  recommendations: any[] | undefined;
  questionnaires: any[] | undefined;
  patients: Patient[];
  commonDetails: CommonType;
  isPsychoTherapistUser: Boolean;
}

// Define the initial state using that type
const initialState: GlobalState = {
  access_token: undefined,
  user: undefined,
  devices: undefined,
  illnesses: [],
  sensors: [],
  patients: [],
  commonDetails: {
    groupedByDropdownFields: [],
    groupedSportTypes: [],
    sportTypeToModule: []
  },
  recommendations: [],
  questionnaires: [],
  isPsychoTherapistUser: false
};

export const globalsSlice = createSlice({
  name: 'globals',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // refresh token and user after user login
    refreshToken: (state, action: PayloadAction<any>) => {
      const newState = {...state, ...action.payload};

      // Update the isPsychoTherapist field if the user is part of the payload
      if (action.payload.user) {
        newState.isPsychoTherapistUser = action.payload.user.roles?.some((r: Role) => r.name === 'PSYCHO') ?? false;
      }

      return newState;
    },
    refreshGlobals: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    refreshPatients: (state, action) => ({
      ...state,
      patients: action.payload,
    }),
    refreshCommonDetails: (state, action: PayloadAction<CommonType>) => {
      state.commonDetails = action.payload;
    },
  },
  extraReducers: (builder) => builder.addDefaultCase((state) => ({...state})),
});

export const {
  refreshToken,
  refreshGlobals,
  refreshPatients,
  refreshCommonDetails
} = globalsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectGlobals = (state: RootState) => state.globals;

export default globalsSlice.reducer;
