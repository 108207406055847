import {createRoot} from 'react-dom/client';
// Apollo client
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  split,
  ApolloLink,
} from '@apollo/client';
// Axios
import axios from 'axios';
import {Chart, registerables} from 'chart.js';
import {QueryClient, QueryClientProvider} from 'react-query';
// Apps
import {GeneralI18nProvider} from './_general/i18n/Generali18n';
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_general/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_general/assets/css/style.rtl.css'
 **/
import './_general/assets/sass/plugins.scss';
import './_general/assets/sass/style.scss';
import './_general/assets/sass/style.react.scss';

import {AppRoutes} from './app/routing/AppRoutes';
import {AUTH_LOCAL_STORAGE_KEY, AuthProvider, setupAxios} from './app/modules/auth';
import {ThemeModeProvider} from './_general/partials/layout/theme-mode/ThemeModeProvider';
import {BACKEND_GRAPHQL_URL, BACKEND_GRAPHQL_WS_URL} from './app/config/backend.config';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import {setContext} from '@apollo/client/link/context';
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios);
Chart.register(...registerables);

const httpLink = new HttpLink({
  uri: BACKEND_GRAPHQL_URL,
});
const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  let token: any = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  // return the headers to the context so httpLink can read them
  token = token ? JSON.parse(token) : null;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.access_token}` : '',
    },
  };
});
const wsLink = new WebSocketLink({
  uri: BACKEND_GRAPHQL_WS_URL,
  options: {
    reconnect: true,
  },
});
const splitLink = split(
  ({query}) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink)
);
const apolloClient = new ApolloClient({
  link: ApolloLink.from([splitLink]),
  cache: new InMemoryCache(),
});
const queryClient = new QueryClient();
const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>
        <GeneralI18nProvider>
          <ThemeModeProvider>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </ThemeModeProvider>
        </GeneralI18nProvider>
        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      </ApolloProvider>
    </QueryClientProvider>
  );
}
