import {
  Device,
  Illness,
  PatientQuestionnaires,
  PatientRecommendations,
  Questionnaire,
  Recommendation,
  Sensor,
} from '../../../../utils/__generated__/graphql';

interface Meta {
  error: string;
  touched: boolean;
  warning: string;
}

export interface FieldProps {
  caption?: string;
  checked?: boolean;
  color?: string;
  description?: string;
  hasError?: boolean;
  icon?: string;
  meta?: Meta;
  name: string;
  onChange?: any;
  placeholder?: string;
  required?: boolean;
  title?: string;
  type: string;
  value?: string;
}

export const allowedTypes = {
  text: 'text',
  checkbox: 'checkbox',
  select: 'select',
  textarea: 'textarea',
};

export interface IParticipantDevice {
  device: Device | undefined;
  sensors: Sensor[];
}

export interface IProfileFields {
  [key: string]: string;
}

export interface ICreateParticipantData {
  patientId: number;
  inSportTherapy: boolean;
  illness: Illness | undefined;
  patientDevice: IParticipantDevice;
  profileFields: IProfileFields[];
  qrCode: string;
  questionnaires: Questionnaire[];
  questionnairesMetadata: PatientQuestionnaires[];
  recommendations: Recommendation[];
  recommendationsMetadata: PatientRecommendations[];
  subjectId: string;
}

export const defaultCreateAppData: ICreateParticipantData = {
  patientId: 0,
  inSportTherapy: false,
  illness: undefined,
  patientDevice: {device: undefined, sensors: []},
  profileFields: [],
  qrCode: '',
  questionnaires: [],
  questionnairesMetadata: [],
  recommendations: [],
  recommendationsMetadata: [],
  subjectId: '',
};

export type StepProps = {
  data: ICreateParticipantData;
  graphQlError?: string;
  hasError: boolean;
  updateData: (fieldsToUpdate: Partial<ICreateParticipantData>) => void;
};
