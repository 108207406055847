import {lazy, FC, Suspense} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {MasterLayout} from '../../_general/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import {getCSSVariableValue} from '../../_general/assets/ts/_utils';
import {WithChildren} from '../../_general/helpers';
import {AdministrationWrapper} from '../pages/Administration';

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const ParticipantPage = lazy(() => import('../modules/participants/PatientPage'));
  const UsersPage = lazy(() => import('../modules/apps/patient-management/PatientsPage'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Patients after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/apps/user-management/users' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='administration' element={<AdministrationWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='participant/profile/*'
          element={
            <SuspensedView>
              <ParticipantPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {PrivateRoutes};
