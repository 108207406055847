/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, useEffect, useState} from 'react';
import {StepProps} from '../IPatientModels';
import {FormattedMessage} from 'react-intl';
import {KTSVG} from '../../../../helpers';
import {useSelector} from 'react-redux';
import {selectGlobals} from '../../../../../app/store/globals.slice';

const Step2 = ({data, updateData}: StepProps) => {
  const {sensors, devices} = useSelector(selectGlobals);
  const [selectedDevice, setSelectedDevice] = useState('');

  function getSensors(e: ChangeEvent<HTMLInputElement>) {
    const assignedSensors = data.patientDevice.sensors;
    const currentSensor = sensors.find((s) => s.name === e.target.value);

    return e.target.checked && currentSensor
      ? [...assignedSensors, currentSensor]
      : assignedSensors.filter((s) => s.name !== e.target.value);
  }

  useEffect(() => {
    if (data && data.patientDevice.device) {
      setSelectedDevice(data.patientDevice.device.macAddress);
    }
  }, [data]);

  function onDeviceChange(e: ChangeEvent<HTMLSelectElement>) {
    updateData({
      patientDevice: {
        device: (devices && devices.find((d) => d.macAddress === e.target.value)) || undefined,
        sensors: data.patientDevice.sensors,
      },
    });
  }

  function onSensorsChange(e: ChangeEvent<HTMLInputElement>) {
    updateData({
      patientDevice: {
        device: data.patientDevice.device,
        sensors: getSensors(e),
      },
    });
  }

  function isChecked(name: string) {
    const found = data.patientDevice.sensors.find((s) => s.name === name);
    return !!found;
  }

  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}

          <div className='fv-row mb-10'>
            <label className='required fs-5 fw-semibold mb-2'>
              <FormattedMessage id='PATIENT.MODAL.SMARKO.DEVICE' />
            </label>
            <select
              name='device'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-mb form-select-white w-100'
              defaultValue={selectedDevice}
              onChange={(e) => onDeviceChange(e)}
            >
              {devices && <option value=''></option>}
              {devices &&
                devices.length > 0 &&
                devices.map(({macAddress}) => (
                  <option
                    key={macAddress}
                    value={macAddress}
                    selected={selectedDevice === macAddress}
                  >
                    {macAddress}
                  </option>
                ))}
              {(!devices || devices.length === 0) && (
                <option>
                  <FormattedMessage id='PATIENT.MODAL.SMARKO.NO.DEVICES' />
                </option>
              )}
            </select>
          </div>
          {/*end::Form Group */}

          {/*begin::Form Group */}
          <div className='fv-row'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span className='required'>
                <FormattedMessage id='PATIENT.MODAL.SENSORS' />
              </span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Select sensors'
              />
            </label>

            {/*begin:Option */}
            {sensors &&
              sensors.map(({name, icon, color}) => (
                <label
                  key={name}
                  className='d-flex align-items-center justify-content-between cursor-pointer mb-6'
                >
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className='symbol-label bg-light-primary'>
                        <KTSVG path={icon} className={`svg-icon-1 ${color}`} />
                      </span>
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bolder fs-6'>{name}</span>
                      <span className='fs-7 text-muted'>Smarko sensor</span>
                    </span>
                  </span>
                  <span className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      className='form-check-input'
                      name={name}
                      value={name}
                      type='checkbox'
                      checked={isChecked(name)}
                      onChange={(e) => onSensorsChange(e)}
                    />
                  </span>
                </label>
              ))}
            {/*end::Option */}
          </div>
          {/*end::Form Group */}
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  );
};

export {Step2};
