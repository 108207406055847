import React, { useEffect, useState } from "react";
import { KTSVG } from '../../helpers';
import { Modal } from 'react-bootstrap';
import { useFormik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerInput } from "./DatePickerInput";
import { FrequencyField } from "./FrequencyField";
import { DurationUnit, Questionnaire } from "../../../utils/__generated__/graphql";

const emptyMetadata = {
  startDate: new Date().toISOString(),
  personalStudyEndDate: new Date().toISOString(),
  questionnaire: {},
  generalStudyEndDate: new Date().toISOString(),
  frequency: {
    value: 1,
    unit: DurationUnit.Weeks,
  },
};

type ICreateParticipantData = {
  questionnairesMetadata: any[];
};

type UpdateQuestionnaireMetadataProps = {
  data: ICreateParticipantData;
  updateData: (data: any) => void;
  questionnaire: any;
  show: boolean;
  handleClose: () => void;
};

const UpdateQuestionnaireMetadata = ({ data, updateData, questionnaire, show, handleClose }: UpdateQuestionnaireMetadataProps) => {
  const [questionnaireMetadata, setQuestionnaireMetadata] = useState(emptyMetadata);

  useEffect(() => {
    if (questionnaire) {
      const currentMetadata = data.questionnairesMetadata.find(
        m => m.questionnaire?.id === questionnaire.id
      ) || emptyMetadata;

      setQuestionnaireMetadata({ ...emptyMetadata, ...currentMetadata, questionnaire });
    }
  }, [questionnaire]);

  const updateQuestionnaireMetadata = (values: any, questionnaire: Questionnaire) => {
    const updatedMeta = {
      questionnaireId: questionnaire.id || 0,
      repeatable: Boolean(values.frequency?.value),
      ...values
    };

    const updatedQuestionnaires = data.questionnairesMetadata.map(
      m => m.questionnaireId === questionnaire.id ? updatedMeta : m
    );

    updateData({
      questionnairesMetadata: updatedQuestionnaires.some(m => m.questionnaireId === questionnaire.id)
        ? updatedQuestionnaires
        : [...updatedQuestionnaires, updatedMeta]
    });

    handleClose();
  };

  const formik = useFormik({
    initialValues: questionnaireMetadata,
    enableReinitialize: true,
    onSubmit: (values) => updateQuestionnaireMetadata(values, questionnaire)
  });

  return (
    <Modal
      id='kt_modal_add_edit_questionnaire_metadata'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog mw-350px'
      show={show}
      onHide={handleClose}
    >
      <form className='border-dashed' onSubmit={formik.handleSubmit}>
        <div className='modal-header'>
          <h5>{`Metadata for: ${questionnaire.name}`}</h5>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-content'>
          <div className='px-7 py-5'>
            <DatePickerInput label="Begin:" name="startDate" formik={formik} />
            <DatePickerInput label="End:" name="personalStudyEndDate" formik={formik} />

            <div className='mb-5'>
              <FrequencyField formik={formik} />
              <input type='hidden' {...formik.getFieldProps('id')} />
              <input type='hidden' {...formik.getFieldProps('generalStudyEndDate')} />
            </div>

            <div className='d-flex justify-content-end'>
              <button type='submit' className='btn btn-sm btn-primary'>
                Apply
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export { UpdateQuestionnaireMetadata };
