import {useIntl} from 'react-intl';
import {PageTitle} from '../../_general/layout/core';
import {StudyQuestionnairesTable} from '../modules/widgets/StudyQuestionnairesTable';
import {QuestionnairesTable} from '../modules/widgets/QuestionnairesTable';

const AdministrationPage = () => (
  <>
    {/*<div className='row g-5 g-xl-8'>*/}
    {/*  <div className='col-xl-4'>*/}
    {/*    <MixedWidget8*/}
    {/*      className='card-xxl-stretch mb-xl-3'*/}
    {/*      chartColor='success'*/}
    {/*      chartHeight='150px'*/}
    {/*    />*/}
    {/*  </div>*/}

    {/*  <div className='col-xl-8'>*/}
    {/*    <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>*/}
    {/*      <div className='col-xl-3'>*/}
    {/*        <TilesWidget1 className='card-xl-stretch' />*/}
    {/*      </div>*/}
    {/*      <div className='col-xl-9'>*/}
    {/*        <TilesWidget4 className='card-xl-stretch' />*/}
    {/*      </div>*/}
    {/*    </div>*/}

    {/*    <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>*/}
    {/*      <div className='col-xl-6'>*/}
    {/*        <TilesWidget2 className='card-xl-stretch mb-5 mb-xl-8' />*/}
    {/*        <div className='row gx-5 gx-xl-8'>*/}
    {/*          <div className='col-xl-6'>*/}
    {/*            <TilesWidget5*/}
    {/*              className='card-xxl-stretch bg-primary'*/}
    {/*              svgIcon='/media/icons/duotune/general/gen025.svg'*/}
    {/*              titleClass='text-white'*/}
    {/*              descriptionClass='text-white'*/}
    {/*              iconClass='svg-icon-white'*/}
    {/*              title='790'*/}
    {/*              description='New Products'*/}
    {/*            />*/}
    {/*          </div>*/}
    {/*          <div className='col-xl-6'>*/}
    {/*            <TilesWidget5*/}
    {/*              className='card-xxl-stretch bg-body'*/}
    {/*              svgIcon='/media/icons/duotune/general/gen002.svg'*/}
    {/*              titleClass='text-dark'*/}
    {/*              descriptionClass='text-muted'*/}
    {/*              iconClass='svg-icon-success'*/}
    {/*              title='8,600'*/}
    {/*              description='New Customers'*/}
    {/*            />*/}
    {/*          </div>*/}
    {/*        </div>*/}
    {/*      </div>*/}
    {/*      <div className='col-xl-6'>*/}
    {/*        <TilesWidget3 className='card-xl-stretch mb-5 mb-xl-8' />*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</div>*/}

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <QuestionnairesTable className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <StudyQuestionnairesTable className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}
  </>
);

const AdministrationWrapper = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADMINISTRATION'})}</PageTitle>
      <AdministrationPage />
    </>
  );
};

export {AdministrationWrapper};
