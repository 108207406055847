
import { Patient, Questionnaire, Recommendation } from "../../../../utils/__generated__/graphql";
import { ICreateParticipantData } from "./IPatientModels";


export function extractData(data: ICreateParticipantData) {
  const {subjectId, patientId, qrCode, inSportTherapy, questionnairesMetadata} = data;
  const recommendationsId = data.recommendations.map((r) => r.id);
  const questionnairesId = data.questionnaires.map((q) => q.id);
  const deviceId = data.patientDevice.device?.id || 0;
  const illnessesId = data.illness ? [data.illness.id] : [];
  const sensorsId = data.patientDevice.sensors.map((s) => s.id);

  // Filtering questionnairesMetadata based on available questionnaires.
  const filteredQuestionnairesMetadata = questionnairesMetadata.filter(m =>
    questionnairesId.includes(m.questionnaire?.id || 0)
  );

  const modifiedMetadata = filteredQuestionnairesMetadata.map(m => {
    const { frequency } = m;
    let newFrequency = frequency ? (({ __typename, ...rest }) => rest)(frequency) : null;
    return {
      questionnaireId: m.questionnaire?.id || 0,
      repeatable: frequency?.value !== 0,
      frequency: newFrequency
    };
  });

  return {
    subjectId,
    profile: {
      patientId,
      qrcode: qrCode,
      deviceId,
      inSportTherapy,
      illnessesId,
      sensorsId,
    },
    recommendationsId,
    questionnairesMetadata: modifiedMetadata,
    questionnairesId,
  };
}

export const getFieldsToUpdate = (patient: Patient) => {
  const {profile, subjectId, currentQuestionnaires, currentRecommendations} = patient;
  const device = (profile && profile.device) || undefined;
  const sensors = (profile && profile.sensors) || [];
  const filteredRecommendations = currentRecommendations?.map((r) => r.recommendation);
  const filteredQuestionnaires = currentQuestionnaires?.map((r) => r.questionnaire);

  return {
    recommendationsMetadata: currentRecommendations || [],
    recommendations: filteredRecommendations ? (filteredRecommendations as Recommendation[]) : [],
    questionnairesMetadata: currentQuestionnaires || [],
    questionnaires: (filteredQuestionnaires as Questionnaire[]) || [],
    patientDevice: {sensors, device},
    qrCode: profile?.qrcode,
    subjectId,
    patientId: profile?.patientId,
    inSportTherapy: (profile && profile.inSportTherapy) || false,
    illness: profile && profile.illnesses ? profile.illnesses[0] : undefined,
  };
};
