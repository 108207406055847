import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {
  Issue,
  Patient, PsychotherapyProfile, SportTypeWithCode, Therapist, TrainingProtocol, TreatmentPlan,
} from '../../utils/__generated__/graphql';
import { RootState } from "../store";
import { GroupedData } from "../modules/participants/components/DeviceQuestionnaireResponseItem";
import { ConvertedWorkout } from "../modules/participants/components/useWorkoutsMeasurementsHook";

// Define a type for the slice state
export interface PatientState {
  currentPatient: Patient | undefined;
  trainingProtocols: TrainingProtocol[] | null
  questionnaireResponses: GroupedData[];
  workouts: ConvertedWorkout[] | null;
  psychotherapyProfile: PsychotherapyProfile | null;
  treatmentPlans: TreatmentPlan[] | null;
  medicationPlans: TreatmentPlan[] | null;
  otherTherapyPlans: TreatmentPlan[] | null;
  therapists: Therapist[] | [];
  issues: Issue[] | [];
  sportTypeWithCode: SportTypeWithCode[] | null;
}

// Define the initial state using that type
const initialState: PatientState = {
  currentPatient: undefined,
  trainingProtocols: [],
  questionnaireResponses: [],
  workouts: [],
  psychotherapyProfile: null,
  treatmentPlans: [],
  medicationPlans: [],
  otherTherapyPlans: [],
  therapists: [],
  issues: [],
  sportTypeWithCode: [],
};

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setCurrentPatient: (state, action: PayloadAction<Patient>) => ({
      ...state,
      currentPatient: action.payload,
    }),
    setSportTypeWithCode: (state, action: PayloadAction<SportTypeWithCode[]>) => {
      state.sportTypeWithCode = action.payload;
    },
    setTrainingProtocols: (state, action: PayloadAction<TrainingProtocol[]>) => ({
      ...state,
      trainingProtocols: action.payload,
    }),
    setWorkouts: (state, action: PayloadAction<ConvertedWorkout[]>) => ({
      ...state,
      workouts: action.payload,
    }),
    setQuestionnaireResponses: (state, action: PayloadAction<GroupedData[]>) => ({
      ...state,
      questionnaireResponses: action.payload,
    }),
    setPsychotherapyProfile: (state, action: PayloadAction<PsychotherapyProfile>) => {
      state.psychotherapyProfile = action.payload;
    },
    setTreatmentPlans: (state, action: PayloadAction<TreatmentPlan[]>) => {
      state.treatmentPlans = action.payload;
    },
    setMedicationPlans: (state, action: PayloadAction<TreatmentPlan[]>) => {
      state.medicationPlans = action.payload;
    },
    setOtherTherapyPlans: (state, action: PayloadAction<TreatmentPlan[]>) => {
      state.otherTherapyPlans = action.payload;
    },
    setTherapists: (state, action: PayloadAction<Therapist[]>) => {
      state.therapists = action.payload;
    },
    setIssues: (state, action: PayloadAction<Issue[]>) => {
      state.issues = action.payload;
    },
  },
  extraReducers: (builder) => builder.addDefaultCase((state) => ({...state})),
});

export const {
  setCurrentPatient,
  setQuestionnaireResponses,
  setWorkouts,
  setPsychotherapyProfile,
  setTreatmentPlans,
  setMedicationPlans,
  setOtherTherapyPlans,
  setTherapists,
  setIssues,
  setSportTypeWithCode
} = patientSlice.actions;
export const selectPatient = (state: RootState) => state.patient;
export const selectPsychotherapyProfile = (state: RootState) => state.patient.psychotherapyProfile;
export const selectTreatmentPlans = (state: RootState) => state.patient.treatmentPlans;
export const selectMedicationPlans = (state: RootState) => state.patient.medicationPlans;
export const selectOtherTherapyPlans = (state: RootState) => state.patient.otherTherapyPlans;
export const selectTherapists = (state: RootState) => state.patient.therapists;
export const selectIssues = (state: RootState) => state.patient.issues;
export const selectSportTypeWithCode = (state: RootState) => state.patient.sportTypeWithCode;
export default patientSlice.reducer;
