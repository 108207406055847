/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../_general/helpers';
import React, {useState} from 'react';
import {GlobalState, selectGlobals} from '../../store/globals.slice';
import {useSelector} from 'react-redux';
import {AddQuestionnaire} from '../../../_general/partials/modals/AddQuestionnaire';
import {Questionnaire, QuestionnaireKeys} from '../../../utils/__generated__/graphql';

type Props = {
  className: string;
};

export const emptyQuestionnaire: Questionnaire = {
  id: 0,
  name: '',
  body: '',
  key: QuestionnaireKeys.Unknown,
  languageCode: 'de',
  subKey: '',
};

const QuestionnairesTable = ({className}: Props) => {
  const {questionnaires}: GlobalState = useSelector(selectGlobals);
  const [show, setShow] = useState(false);
  const [questionnaire, setQuestionnaire] = useState<Partial<Questionnaire>>();

  function showModal(q: Partial<Questionnaire>) {
    setQuestionnaire(q);
    setShow(true);
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Questionnaires</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            onClick={() => showModal(emptyQuestionnaire)}
          >
            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-1' />
          </button>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        {questionnaires &&
          questionnaires.map(({name, id}, index) => (
            <div key={id} className='d-flex align-items-center mb-8'>
              {/* begin::Bullet */}
              <span className='bullet bullet-vertical h-40px bg-success'></span>
              {/* end::Bullet */}
              {/* begin::Checkbox */}
              <div className='form-check form-check-custom form-check-solid mx-5'>
                <input className='form-check-input' type='checkbox' />
              </div>
              {/* end::Checkbox */}
              {/* begin::Description */}
              <div className='flex-grow-1'>
                <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                  {name}
                </a>
                <span className='text-muted fw-semibold d-block'>Frequency weekly</span>
              </div>
              {/* end::Description */}
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                onClick={() => showModal(questionnaires[index])}
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>
            </div>
          ))}

        {/* end:Item */}
      </div>
      {/* end::Body */}
      {questionnaire && (
        <AddQuestionnaire
          show={show}
          questionnaire={questionnaire}
          handleClose={() => setShow(false)}
        />
      )}
    </div>
  );
};

export {QuestionnairesTable};
