import {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {I18nProvider} from '../_general/i18n/i18nProvider';
import {LayoutProvider, LayoutSplashScreen} from '../_general/layout/core';
import {MasterInit} from '../_general/layout/MasterInit';
import {AuthInit} from './modules/auth';
import {Provider} from 'react-redux';
import {store} from './store';

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Provider store={store}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Provider>
    </Suspense>
  );
};

export {App};
