/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {Modal} from 'react-bootstrap';
import {StepperComponent} from '../../../assets/ts/components';
import {KTSVG} from '../../../helpers';
import {Step1} from './steps/Step1';
import {Step2} from './steps/Step2';
import {Step4} from './steps/Step4';
import {Step5} from './steps/Step5';
import {FormattedMessage} from 'react-intl';
import {StepsSidebar} from './StepsSidebar';
import {useListView} from '../../../../app/modules/apps/patient-management/users-list/core/ListViewProvider';
import usePatientDataManagementHook from "./usePatientDataManagementHook";

type Props = {
  show: boolean;
  handleClose: () => void;
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const CreatePatientModal = ({show, handleClose}: Props) => {
  const {
    data,
    updateData,
    setDataToSend,
    updParticipantData,
    errorUpdParticipant,
    insParticipantData,
    errorInsertParticipant,
  } = usePatientDataManagementHook();
  const {itemIdForUpdate} = useListView();
  const [hasError, setHasError] = useState(false);
  const [graphQlError, setGraphQLError] = useState('');

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  useEffect(() => {
    if (updParticipantData || errorUpdParticipant || insParticipantData || errorInsertParticipant) {
      if (errorInsertParticipant || errorUpdParticipant) {
        setHasError(true);
        const errorText =
          errorUpdParticipant?.message ||
          errorInsertParticipant?.message ||
          'unknown error from graphql';
        setGraphQLError(errorText);
      } else {
        handleClose();
      }
    }
  }, [updParticipantData, errorUpdParticipant, insParticipantData, errorInsertParticipant]);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
  };

  const checkParticipantBasic = (): boolean => {
    return !(!data.patientId || !data.illness);
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current.goPrev();
  };

  const nextStep = () => {
    setHasError(false);
    if (!stepper.current) {
      return;
    }
    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkParticipantBasic()) {
        setHasError(true);
        return;
      }
    }

    stepper.current.goNext();
  };

  function onSubmit() {
    setDataToSend(data);
  }

  return createPortal(
    <Modal
      id='kt_modal_create_participant'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
    >
      <div className='modal-header'>
        {!itemIdForUpdate && (
          <h2>
            <FormattedMessage id='PATIENT.MODAL.CREATE.TITLE' />
          </h2>
        )}
        {itemIdForUpdate && (
          <h2>
            <FormattedMessage id='PATIENT.MODAL.UPDATE.TITLE' />
          </h2>
        )}
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_participant_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <StepsSidebar />
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_participant_form'>
              {/* begin::Scroll */}
              <Step1 data={data} updateData={updateData} hasError={hasError} />
              <Step2 data={data} updateData={updateData} hasError={hasError} />
              {/*<Step3 data={data} updateData={updateData} hasError={hasError} />*/}
              <Step4 data={data} updateData={updateData} hasError={hasError} />
              <Step5
                data={data}
                updateData={updateData}
                hasError={hasError}
                graphQlError={graphQlError}
              />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    Previous
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='submit'
                    onClick={onSubmit}
                  >
                    Submit{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </button>

                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Next Step{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  );
};

export {CreatePatientModal};
