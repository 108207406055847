import {useEffect, useState} from 'react';
import { refreshCommonDetails, refreshGlobals, selectGlobals } from "../../../../app/store/globals.slice";
import {useSelector} from 'react-redux';
import {
  useDevicesLazyQuery, useGetCommonDetailsLazyQuery,
  useIllnessesLazyQuery, usePatientsLazyQuery,
  useQuestionnairesLazyQuery,
  useRecommendationsLazyQuery,
  useSensorsLazyQuery
} from "../../../../utils/__generated__/graphql";
import {store} from '../../../../app/store';

const useGlobalsHook = () => {
  const [loading, setLoading] = useState(false);
  const {isPsychoTherapistUser,user} = useSelector(selectGlobals);

  const [getIllnesses, {data: illnesses, error: errorIllness}] = useIllnessesLazyQuery();
  const [getRecommendations, {data: recommendations, error: errorRecommendation}] =
    useRecommendationsLazyQuery();
  const [getQuestionnaires, {data: questionnaires, error: errorQuestionnaire}] =
    useQuestionnairesLazyQuery();
  const [getDevices, {data: devices, error: errorDevice}] = useDevicesLazyQuery();
  const [getSensors, {data: sensors, error: errorSensor}] = useSensorsLazyQuery();
  const [getPatients, {data: patients, error: errorParticipant}] =
    usePatientsLazyQuery();
  const [getCommonDetails, { data: commonDetails, error: errorCommonDetails }] = useGetCommonDetailsLazyQuery();

  useEffect(() => {
    if (user) {
      setLoading(true);
      getSensors().then(() => {});
      getDevices().then(() => {});
      getIllnesses().then(() => {});
      getPatients().then(() => {});
      getRecommendations().then(() => {});
      getQuestionnaires().then(() => {});
      getCommonDetails().then(() => {});
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (patients && user) {
      let filteredPatients = patients.patients;

      if (isPsychoTherapistUser && !user.isAdmin) {
        filteredPatients = filteredPatients.filter(patient => patient.hasPsychoProfile);
      }

      if (!isPsychoTherapistUser && !user.isAdmin) {
        filteredPatients = filteredPatients.filter(patient => !patient.hasPsychoProfile);
      }

      store.dispatch(refreshGlobals({ patients: filteredPatients }));
    }
  }, [patients, isPsychoTherapistUser, user]);

  useEffect(() => {
    if (illnesses) {
      store.dispatch(refreshGlobals({illnesses: illnesses.illnesses}));
    }
  }, [illnesses]);

  useEffect(() => {
    if (commonDetails) {
      store.dispatch(refreshCommonDetails(commonDetails.getCommonDetails));
    }
  }, [commonDetails]);

  useEffect(() => {
    if (recommendations) {
      store.dispatch(refreshGlobals({recommendations: recommendations.recommendations}));
    }
  }, [recommendations]);

  useEffect(() => {
    if (questionnaires) {
      store.dispatch(refreshGlobals({questionnaires: questionnaires.questionnaires}));
    }
  }, [questionnaires]);

  useEffect(() => {
    if (devices) {
      store.dispatch(refreshGlobals({devices: devices.devices}));
    }
  }, [devices]);

  useEffect(() => {
    if (sensors) {
      store.dispatch(refreshGlobals({sensors: sensors.sensors}));
    }
  }, [sensors]);

  return {loading, errorIllness, errorDevice, errorSensor, errorParticipant, errorCommonDetails };
};

export default useGlobalsHook;
