/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../../helpers';
import {StepProps} from '../IPatientModels';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {GlobalState, selectGlobals} from '../../../../../app/store/globals.slice';
import React, {ChangeEvent, useEffect, useState} from 'react';

const Step1 = ({data, updateData, hasError}: StepProps) => {
  const {illnesses}: GlobalState = useSelector(selectGlobals);

  function onIllnessChange(e: ChangeEvent<HTMLInputElement>) {
    const illness = (illnesses && illnesses.find((i) => i.name === e.target.value)) || undefined;
    updateData({illness});
  }

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group Patient id */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>
              <FormattedMessage id='PATIENT.MODAL.PATIENT_ID' />
            </span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify a unique patient id'
            />
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='patientId'
            placeholder=''
            value={data.patientId === 0 ? '' : data.patientId}
            onChange={(e) => updateData({patientId: parseInt(e.target.value)})}
          />
          {!data.patientId && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='patientId' data-validator='notEmpty' className='fv-help-block'>
                <FormattedMessage id='PATIENT.MODAL.PATIENT_ID.REQUIRED' />
              </div>
            </div>
          )}
        </div>
        {/*end::Form Group Patient id */}
        {/*begin::Form Group sport therapy */}
        <div className='fv-row'>
          <label className='d-flex align-items-center justify-content-between cursor-pointer mb-6'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label bg-light-primary'>
                  <KTSVG
                    path='/media/icons/duotune/medicine/med001.svg'
                    className={`svg-icon-1 svg-icon-success`}
                  />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>In Sport therapy</span>
                <span className='fs-7 text-muted'>Sport therapy group</span>
              </span>
            </span>
            <span className='form-check form-check-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                name='inSportTherapy'
                type='checkbox'
                checked={data.inSportTherapy}
                onChange={({target}) => updateData({inSportTherapy: target.checked})}
              />
            </span>
          </label>
        </div>
        {/*end::Form Group sport therapy */}
        {/*begin::Form Group */}
        <div className='fv-row'>
          {/* begin::Label */}
          <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>
              <FormattedMessage id='PATIENT.MODAL.ILLNESS' />
            </span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Select an illness'
            />
          </label>
          {!data.illness && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='illness' data-validator='notEmpty' className='fv-help-block'>
                <FormattedMessage id='PATIENT.MODAL.ILLNESS.REQUIRED' />
              </div>
            </div>
          )}
          {/* end::Label */}
          <div>
            {/*begin:Option */}
            {illnesses &&
              illnesses.map(({name, description, icon, color}) => (
                <label
                  key={name}
                  className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'
                >
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className='symbol-label bg-light-primary'>
                        <KTSVG path={icon} className={`svg-icon-1 ${color}`} />
                      </span>
                    </span>
                    <span className='d-flex flex-column'>
                      <span className='fw-bolder fs-6'>{name}</span>
                      <span className='fs-7 text-muted'>{description}</span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name={name}
                      value={name}
                      checked={data.illness?.name === name}
                      onChange={(e) => onIllnessChange(e)}
                    />
                  </span>
                </label>
              ))}
            {/*end::Option */}
          </div>
        </div>
        {/*end::Form Group */}
      </div>
    </div>
  );
};

export {Step1};
