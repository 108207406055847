import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAuth} from './core/Auth';
import {GlobalState, refreshToken, selectGlobals} from '../../store/globals.slice';
import {useLoginLazyQuery} from '../../../utils/__generated__/graphql';
import {store} from '../../store';

const useAuthHook = () => {
  const [loading, setLoading] = useState(false);
  const {saveAuth, setCurrentUser} = useAuth();
  const globals: GlobalState = useSelector(selectGlobals);
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const getBaseOptions = () => {
    return {
      variables: {
        loginUserInput: {email: loginData.email, password: loginData.password},
      },
    };
  };

  const [login, {data: userData, error}] = useLoginLazyQuery(getBaseOptions());
  useEffect(() => {
    if (loginData.email !== '') {
      login(getBaseOptions()).then(() => {});
    }
  }, [loginData]);

  useEffect(() => {
    if (userData) {
      store.dispatch(refreshToken(userData.login));
    }
  }, [userData]);

  useEffect(() => {
    if (globals.access_token) {
      const {user, access_token} = globals;
      saveAuth({access_token});
      setCurrentUser(user);
      setLoading(false);
    }
  }, [saveAuth, globals, setCurrentUser]);

  return {loading, setLoading, globals, setLoginData, userData, error};
};

export default useAuthHook;
