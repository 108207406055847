/* eslint-disable jsx-a11y/anchor-is-valid */
import {StepProps} from '../IPatientModels';
import QRCode from 'qrcode';
import {useEffect, useState} from 'react';
import uuid from 'react-uuid';
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider';

const Step5 = ({data, updateData, hasError, graphQlError}: StepProps) => {
  const [subjectId] = useState(uuid());
  const [participantData] = useState(
    JSON.stringify({AppIdentifier: 'Decide', subjectId: data.subjectId || subjectId})
  );
  const {mode} = useThemeMode();
  const [qr, setQr] = useState('');

  const GenerateQRCode = () => {
    const color = mode === 'light' ? {light: '#EEEEEEFF'} : {dark: '#335383FF'};
    QRCode.toDataURL(participantData, {margin: 2, color}, (err, qrCode) => {
      if (err) return console.error(err);
      setQr(qrCode);
      updateData({qrCode, subjectId: data.subjectId || subjectId});
    });
  };

  useEffect(() => {
    GenerateQRCode();
  }, [data]);

  return (
    <>
      {/*begin::Step 4 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>{qr && <img src={qr} width='100%' height='100%' alt='' />}</div>
      </div>
      {hasError && graphQlError && (
        <div className='fv-plugins-message-container'>
          <div data-validator='notEmpty' className='fv-help-block'>
            {graphQlError}
          </div>
        </div>
      )}
      {/*end::Step 4 */}
    </>
  );
};

export {Step5};
