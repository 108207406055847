import React from 'react';
import { DurationUnit } from "../../../utils/__generated__/graphql";

interface DurationFieldProps {
  formik: any;
}

export const FrequencyField = ({ formik }: DurationFieldProps) => {
  if (!formik) {
    return null;
  }

  return (
    <div className='mb-5'>
      <div className='d-flex align-items-center'>
        <label className='fs-5 fw-semibold mb-2'>
          <span className='required'>Frequency:</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Specify the Frequency'
          />
        </label>
      </div>
      <div className='d-flex align-items-center'>
        <input
          type='number'
          className='form-control form-control-lg form-control-solid me-3'
          placeholder='Value'
          value={formik.values.frequency.value} // The current value
          onChange={(e) => {
            formik.setFieldValue('frequency.value', e.target.value);
          }}
          {...formik.getFieldProps('frequency.value')}
        />
        <select
          className='form-select form-select-solid'
          data-kt-select2='true'
          data-placeholder='Select unit'
          data-allow-clear='true'
          value={formik.values.frequency.unit} // The current value
          onChange={(e) => {
            formik.setFieldValue('frequency.unit', e.target.value);
          }}
          {...formik.getFieldProps('frequency.unit')}
        >
          <option></option>
          {Object.keys(DurationUnit).map((unitKey: string) => (
            <option key={unitKey} value={DurationUnit[unitKey as keyof typeof DurationUnit]}>{unitKey}</option>
          ))}
        </select>
      </div>
    </div>
  );
};
