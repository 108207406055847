/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {KTSVG} from '../../helpers';
import {Modal} from 'react-bootstrap';
import clsx from 'clsx';
import {useThemeMode} from '../layout/theme-mode/ThemeModeProvider';
import {
  Questionnaire,
  useCreateQuestionnaireMutation,
  useUpdateQuestionnaireMutation,
} from '../../../utils/__generated__/graphql';
import {useFormik} from 'formik';

type Props = {
  questionnaire: Partial<Questionnaire>;
  show: boolean;
  handleClose: () => void;
};

const AddQuestionnaire = ({questionnaire, show, handleClose}: Props) => {
  const {mode} = useThemeMode();
  const [hasError, setHasError] = useState('');

  const formik = useFormik<Partial<Questionnaire>>({
    initialValues: questionnaire,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      if (values.id === 0) {
        await createQuestionnaire(getBaseCreateOptions(values));
      } else {
        await updateQuestionnaire(getBaseUpdateOptions(values));
      }
    },
  });

  function getBaseUpdateOptions(values: Partial<Questionnaire>) {
    const {id, name, body, languageCode} = values;
    return {
      variables: {
        updateQuestionnaireInput: {
          id: id || 0,
          name,
          body,
          languageCode,
        },
      },
    };
  }

  function getBaseCreateOptions(values: Partial<Questionnaire>) {
    const {name, body, languageCode} = values;
    return {
      variables: {
        createQuestionnaireInput: {
          name: name || '',
          body: body || '',
          languageCode: languageCode || 'de',
        },
      },
    };
  }
  const [updateQuestionnaire, {data, error}] = useUpdateQuestionnaireMutation(
    getBaseUpdateOptions(formik.values)
  );
  const [createQuestionnaire, {data: dataCreate, error: errorCreate}] =
    useCreateQuestionnaireMutation(getBaseCreateOptions(formik.values));

  useEffect(() => {
    if (error || errorCreate) {
      const err = error?.message || errorCreate?.message;
      if (err) {
        setHasError(err);
      }
    } else {
      setHasError('');
    }
    if (data || dataCreate) {
      handleClose();
    }
  }, [error, errorCreate, data, dataCreate]);
  return (
    <Modal
      id='kt_modal_add_edit_questionnaire'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-350px'
      show={show}
      onHide={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className='modal-header'>
          <h2>Add questionnaire</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>
        <div className='modal-content'>
          <div className='px-7 py-5'>
            <div>
              <div className='mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Name:</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify the questionnaire name'
                  />
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  {...formik.getFieldProps('name')}
                />
              </div>
              <div className='mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Body:</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify the questionnaire name'
                  />
                </label>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  {...formik.getFieldProps('body')}
                />
              </div>
              <div className='mb-5'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Language:</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Frequency to fill the questionnaire'
                  />
                </label>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  defaultValue={'de'}
                  {...formik.getFieldProps('languageCode')}
                >
                  <option></option>
                  <option value='de'>de</option>
                  <option value='en'>en</option>
                </select>
                <input type='hidden' {...formik.getFieldProps('id')} />
              </div>
            </div>
            {hasError !== '' && (
              <div className='fv-plugins-message-container'>
                <div data-validator='notEmpty' className='fv-help-block'>
                  {hasError}
                </div>
              </div>
            )}
            <div className='d-flex justify-content-end'>
              <button
                type='reset'
                className={clsx('btn btn-sm btn-active-light-primary me-2', {
                  'btn-light ': mode === 'light',
                })}
                data-kt-menu-dismiss='true'
              >
                Reset
              </button>

              <button type='submit' className='btn btn-sm btn-primary'>
                Apply
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export {AddQuestionnaire};
